import { graphql, useStaticQuery, navigate } from 'gatsby'
import React, { ReactElement, useState } from 'react'

import DestinationsLayout from '../../components/Layout/Destinations'
import Hero from '../../components/DestinationsPage/Hero'
import DestinationsDetails from '../../components/DestinationsPage/DestinationsDetails'
import MenuBaseDetails from '../../components/MenuBaseDetails'
import SEO from '../../components/SEO/SEO'
import { DESTINATIONS, DestinationsTypes } from '../../components/DestinationsPage/data'

const DestinationsPage = (props: any): ReactElement => {
  const { pageContext } = props
  const { data } = pageContext
  const allDestinationsPage = useStaticQuery(graphql`
    query allDestinations {
      allSitePage(filter: { context: { category: { eq: "destinations" } } }) {
        edges {
          node {
            path
            context {
              data {
                id
                estate
                description
                title
                headingTag
                seoTitle
                seoDescription
                updated
                feature
                featuredMedia {
                  media
                  mediaType
                }
              }
            }
          }
        }
      }
    }
  `)

  const allDestinations = allDestinationsPage.allSitePage.edges

  const indexDestination = (): number => {
    let tempIndex = 0
    allDestinations.map((resDestination: any, indexResDestination: number) =>
      resDestination.node.context.data.id === data.id ? (tempIndex = indexResDestination) : null
    )
    return tempIndex
  }

  const featured = allDestinations.filter((resFeatured: any) => resFeatured.node.context.data.feature === true)
  const [index, setIndex] = useState(indexDestination)

  const indexCount = index + 1
  const prevIndex = index === 0 ? allDestinations.length - 1 : index - 1
  const nextIndex = indexCount === allDestinations.length ? 0 : indexCount

  return (
    <>
      <DestinationsLayout>
        <SEO seoTitle={data.seoTitle} seoDescription={data.seoDescription} title={`${data.estate} - ALISI | Ayala Land`} />
        <h1 style={{ display: 'none' }}>{data.headingTag}</h1>
        <Hero data={{ context: { data } }} detail={true} />
        <DestinationsDetails featured={featured} data={data} />
      </DestinationsLayout>
      <MenuBaseDetails
        title={data.title}
        prevTitle={allDestinations[prevIndex].node.context.data.title}
        prevOnClick={() => navigate(allDestinations[prevIndex].node.path)}
        nextTitle={allDestinations[nextIndex].node.context.data.title}
        nextOnClick={() => navigate(allDestinations[nextIndex].node.path)}
        url={data.slug}
        count={allDestinations.length}
      />
    </>
  )
}

export default DestinationsPage
